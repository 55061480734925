import { Environment, l10n } from './shared.models';

export const environment: Environment = {
  production: false,
  devTools: true,
  hmr: false,
  name: 'staging-na',
  authEnv: 'production',
  appUrl: 'https://maps.staging.production.zonarsystems.net',
  useSettingsApi: false,
  datadog: {
    applicationId: '6f9abbe4-1e1f-4150-b7d9-5f5bef0cb1c0',
    clientToken: 'pub9e3e901f623850797e3a6ece6bf4efda',
    tagEnvironment: 'staging'
  },
  devCycle: {
    clientId: 'dvc_client_dcb6e1cd_a623_4d29_b200_cb26c74eb34b_d45aa23'
  },
  here: {
    apiKeyV3: 'olkU4lV-y68dU63MV78jBv8yj6tkj4qRChXyzPfdr2A'
  },
  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: 'mvIS1MKUWMyBOIZODYerVw',
    environment: 'env-5'
  },
  auth: {
    clientID: 'IbzxashTnhEe731aBjGiMSP1ok1VrO84',
    domain: 'zonar-login.auth0.com',
    audience: 'https://api.zonarsystems.net',
    applicationId: '8bb1e894-eac4-4cf8-887a-5341d5a06b44',
    useRefreshTokens: true,
    maxAge: 36000
  },
  userPreferenceApiBase: {
    url: 'https://api.zonarsystems.net/alert/v1'
  },
  locationApi: {
    url: 'https://location-writer-db.staging.production.zonarsystems.net'
  },
  autocompleteApi: {
    url: 'https://location-api.staging.production.zonarsystems.net/api/v1beta2/autocomplete',
    pageSize: 10
  },
  apiBase: {
    url: 'https://api.zonarsystems.net'
  },
  coreEntityApiBase: {
    url: 'https://api.zonarsystems.net/core/v1'
  },
  historicalApiBase: {
    url: 'https://gtcx-historical-api.staging.production.zonarsystems.net/api/v1'
  },
  dataUpdateMessages: {
    // units are minutes
    updateInterval: 1,
    idling: 60 * 12,
    notIdling: 15
  },
  nearbyAssets: {
    searchRadiusMeters: 50 * 1609, // 50 miles in meters
    assetsToReturn: 10
  },
  liveUpdate: {
    pageSize: 300,
    pollingInterval: 10_000,
    consecutiveFailureLimit: 3,
    restartPollingDelay: 15_000
  },
  zTrakBatteryAlertPct: 0.1,
  apiRequestPageSize: 5000,
  datadogSite: 'us5.datadoghq.com',
  minimumTripMS: 60000,
  featureFlags: {
    assetIOs: false
  },
  region: 'NA',
  gtcxUrl: {
    activityFeedUrl: 'https://activity-feed-ui.staging.production.zonarsystems.net/',
    incidentInvestigation: 'https://analytics.staging.production.zonarsystems.net/'
  },
  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'es-ES', 'fr-FR', 'it-IT'],
    defaultLanguage: 'en-US'
  },
  l10n,
  hamburgerMenuEnabled: true
};
